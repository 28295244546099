import { ReactKeycloakProvider } from '@react-keycloak/web'
import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import MainErrorBoundary from './api/components-examples/utils/ErrorBoundary/MainErrorBoundary'
import Dashboard from './Dashboard'
import keycloak from './KeyCloak/keycloak'
import Error from './KeyCloak/KeycloakError'
import { persistor } from './store'

const App = () => {
  const [isKeycloakError, setIsKeycloakError] = useState(false)

  const eventLogger = (event, error) => {
    if (event === 'onInitError') {
      setIsKeycloakError(true)
    }
    if (event === 'onTokenExpired') {
      console.log('TOKEN IS EXPIRED NOW')
      keycloak.logout();
    }
    if (event === 'onAuthRefreshError') {
      console.log('Session expired, Please login again');
    }
  }

  const tokenLogger = tokens => {
    try {
      if (tokens && tokens.tokenParsed) {
        const expirationTime = tokens.tokenParsed.exp * 1000;
        const timeLeft = expirationTime - Date.now();

        if (timeLeft <= 0) {
          keycloak.logout();
        }
      }
    } catch (error) {

    }
  }

  return (
    <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
      <MainErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <Router>{!isKeycloakError ? <Dashboard /> : <Error />}</Router>
        </PersistGate>
      </MainErrorBoundary>
    </ReactKeycloakProvider>
  )
}

export default App
