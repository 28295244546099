import { useEffect, useState, useRef } from 'react'

const SessionIdle = ({ sessionExpire, idleTimeout = 400000 }) => {
    const [isIdle, setIsIdle] = useState(false)
    const idleTimerRef = useRef(null)

    const handleUserActivity = () => {
        if (isIdle) {
            setIsIdle(false)
        }

        // Clear previous timeout
        if (idleTimerRef.current) {
            clearTimeout(idleTimerRef.current)
        }

        // Start new timeout
        idleTimerRef.current = setTimeout(() => {
            setIsIdle(true)
            sessionExpire()
        }, idleTimeout)
    }

    useEffect(() => {
        window.addEventListener('mousemove', handleUserActivity)
        window.addEventListener('keydown', handleUserActivity)

        // Initialize idle timer on mount
        handleUserActivity()

        return () => {
            window.removeEventListener('mousemove', handleUserActivity)
            window.removeEventListener('keydown', handleUserActivity)

            // Cleanup timeout on unmount
            if (idleTimerRef.current) {
                clearTimeout(idleTimerRef.current)
            }
        }
    }, [idleTimeout]) // Re-run effect if idleTimeout changes

    return null
}

export default SessionIdle
