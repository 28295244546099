import qs from 'qs'
import { RSAA } from 'redux-api-middleware'
import reduxStore from '../store.js'
import actionTypes from './redux/actionTypes.js'

const getSpaceId = (cUser, key) => {
  return cUser !== null && cUser.spaceId !== undefined && cUser.spaceId !== null && cUser.spaceId !== 'undefined'
    ? `?${qs.stringify({ [key]: cUser.spaceId }, { indices: false })}`
    : ''
}

export default class API {
  constructor(config) {
    const { apiRootUrl, apiV2RootUrl, apiV3RootUrl, apiV4RootUrl, wssUrl, fileRootUrl } = config
    this.apiRootUrl = apiRootUrl
    this.apiV2RootUrl = apiV2RootUrl
    this.apiV3RootUrl = apiV3RootUrl
    this.apiV4RootUrl = apiV4RootUrl
    this.wssUrl = wssUrl
    this.fileRootUrl = fileRootUrl
    this.isInitialized = true
  }

  static createInitAPIAction() {
    return {
      type: actionTypes.INIT_API_REQUEST
    }
  }

  createRSAATriplet(prefix) {
    const _name = prefix.toUpperCase()
    const keys = Object.keys(actionTypes)
    const actions = keys.filter(k => k.startsWith(_name))
    return actions
  }

  keycloakToken() {
    const currentState = reduxStore.getState()
    const {
      auth: { keycloak, isAuthenticated }
    } = currentState
    if (!isAuthenticated || keycloak == null) return null
    return keycloak.token
  }

  authorizationHeader() {
    var authHeader = {}
    const token = this.keycloakToken()
    if (token != null) {
      authHeader['Authorization'] = `Bearer ${token}`
    }
    return authHeader
  }

  createRSAA(config) {
    var headers =
      config.headers != null ? Object.assign(config.headers, this.authorizationHeader()) : this.authorizationHeader()
    var types = this.createRSAATriplet(config.actionPrefix)

    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}${config.endpoint}`,
        method: config.method,
        body: config.body,
        headers,
        types
      }
    }
  }

  //For Profiling Admin
  createFetchMyProfileRSAA(orgId, spaceId) {
    var types = this.createRSAATriplet('FETCH_MY_PROFILE')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint:
          spaceId !== undefined && spaceId !== 'undefined'
            ? `${this.apiRootUrl}/profile?orgId=${orgId}&spaceId=${spaceId}`
            : `${this.apiRootUrl}/profile?orgId=${orgId}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchHelpLinksRSAA() {
    // var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_HELP_LINKS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: 'https://storage.googleapis.com/dt-static/docs/datoin-content-index.json',
        method: 'GET',
        types
      }
    }
  }

  createFetchDatoinservicesRSAA() {
    return this.createRSAA({
      endpoint: '/datoinServices',
      actionPrefix: 'FETCH_WELLKNOWN',
      method: 'GET'
    })
  }

  createResetSandboxRunAction() {
    return {
      type: actionTypes.RESET_APP_SANDBOX_RUN_REQUEST
    }
  }

  createDatasetAddItemsRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/data/${id}/items`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'ADD_ITEMS_TO_DATASET'
    })
  }

  createFetchModelDetailRSAA(id, current_logged_user) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_MODEL_DETAIL')
    types = types.map(type => {
      return { type: type, meta: { id: id } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/models/${id}/details`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  createFetchDSItemSummaryRSAA(id) {
    return this.createRSAA({
      endpoint: `/pdf/${id}/annotationSummary`,
      method: 'GET',
      actionPrefix: 'FETCH_DATASET_ITEM_ANNOTATION_SUMMARY',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDelteRequestUploadingDataSet(datasetId, current_logged_user) {
    return {
      [RSAA]: {
        endpoint: `${this.fileRootUrl}/${current_logged_user.orgId}/upload/${datasetId}?spaceId=${current_logged_user.spaceId}&orgId=${current_logged_user.orgId}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader(), { 'Tus-Resumable': '1.0.0' }),
        types: [
          'DELETE_DATASET_TUSIO_REQUEST',
          {
            type: 'DELETE_DATASET_TUSIO_SUCCESS',
            meta: { id: datasetId }
          },
          'DELETE_DATASET_TUSIO_FAILURE'
        ]
      }
    }
  }

  createUpdateStatusListOfBgJobs(_params) {
    let actionTypes = [
      'DELETE_ITEM_STATUS_LIST_REQUEST',
      'DELETE_ITEM_STATUS_LIST_SUCCESS',
      'DELETE_ITEM_STATUS_LIST_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/stickyWindow/tasks`,
        method: 'DELETE',
        body: _params.body,
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  //For Profiling Admin
  createFetchUserAccountInfo() {
    var types = this.createRSAATriplet('FETCH_USER_ACCOUNT_INFO')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/profile/userRoles/user`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchUserAccountInfoSilently() {
    var types = this.createRSAATriplet('SILENTLY_FETCH_USER_ACCOUNT_INFO')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/profile/userRoles/user`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  //For Profiling Admin
  createFetchSendInvitationCode(invitationCode) {
    var types = this.createRSAATriplet('SEND_INVITATION_CODE')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `
        ${this.apiV3RootUrl}/org/profile/userRoles/invite/${invitationCode}`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createTusAddItemsRSAA(id, params, current_logged_user) {
    var types = this.createRSAATriplet('ADD_ITEMS_TO_DATASET_TUS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV4RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/items/upload`,
        method: 'POST',
        headers: this.authorizationHeader(),
        types,
        body: params.body
      }
    }
  }

  createGenerateCharacteristics(datasetId, org, lockItem) {
    let actionTypes = [
      'GENERATE_CHARACTERISTICS_REQUEST',
      'GENERATE_CHARACTERISTICS_SUCCESS',
      'GENERATE_CHARACTERISTICS_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/datasets/${datasetId}/generateCharacteristics`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  //Added for TusIO validate Dataset
  createDataSetPreCheck(_params, current_logged_user) {
    let actionTypes = [
      'DATASET_EXISTS_PRECHECK_REQUEST',
      'DATASET_EXISTS_PRECHECK_SUCCESS',
      'DATASET_EXISTS_PRECHECK_FAILURE'
    ]
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/upload/validate${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  //Added for TusIO validate  Dataset Item
  createDataSetItemPreCheck(datasetId, params, current_logged_user) {
    let actionTypes = [
      'DATASET_ITEM_EXISTS_PRECHECK_REQUEST',
      'DATASET_ITEM_EXISTS_PRECHECK_SUCCESS',
      'DATASET_ITEM_EXISTS_PRECHECK_FAILURE'
    ]

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${datasetId}/datasetitems/upload/validate`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        body: params.body,
        types: actionTypes
      }
    }
  }

  createFetchMyDatasetsEmbedRSAA(params) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV2RootUrl}/data${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: ['FETCH_MY_DATASETS_EMBED_REQUEST', 'FETCH_MY_DATASETS_EMBED_SUCCESS', 'FETCH_MY_DATASETS_EMBED_FAILURE']
      }
    }
  }

  createFetchDatasetDetailRSAA(id, silent, groupId, current_conf) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_DATASET_DETAIL' : 'FETCH_DATASET_DETAIL'
    return {
      [RSAA]: {
        endpoint: groupId
          ? `${this.apiV4RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/datasets/${id}/items?groupName=${groupId}`
          : `${this.apiV4RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/datasets/${id}/items?isNullableGroup=true`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: ['FETCH_DATASET_DETAIL_REQUEST', 'FETCH_DATASET_DETAIL_SUCCESS', 'FETCH_DATASET_DETAIL_FAILURE']
      }
    }
  }

  createProjectUpdatRSeAA(current_logged_user, params) {
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/updateProject`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: ['FETCH_PROJECT_API_REQUEST', 'FETCH_PROJECT_API_SUCCESS', 'FETCH_PROJECT_API_FAILURE'],
        body: params
      }
    }
  }

  createFetchDatasetDetailEmbedRSAA(id, groupId, current_logged_user) {
    return {
      [RSAA]: {
        endpoint: groupId
          ? `${this.apiV4RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/items?groupName=${groupId}`
          : `${this.apiV4RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/items?isNullableGroup=true`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: [
          'FETCH_DATASET_DETAIL_EMBED_REQUEST',
          'FETCH_DATASET_DETAIL_EMBED_SUCCESS',
          'FETCH_DATASET_DETAIL_EMBED_FAILURE'
        ]
      }
    }
  }

  deleteDSSetRSSA(form_data, current_logged_user, datasetItem) {
    var types = this.createRSAATriplet('DELETE_DSSET')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types,
        body: form_data
      }
    }
  }

  createCreateDatasetRSAA(params, current_logged_user, queryStr) {
    var types = this.createRSAATriplet('CREATE_DATASET')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets${queryStr}`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  createCreateDatasetFolderRSAA(params, current_logged_user) {
    var types = this.createRSAATriplet('CREATE_FOLDER_DATASETS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/data/folder`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  createFetchDatasetItemSampleRSAA(id, current_logged_user) {
    var headers = Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader())
    var types = this.createRSAATriplet('FETCH_DATASET_ITEM_SAMPLE')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/items/${id}/sample?num=11`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  createDownloadDatasetItemPreCheckSampleRSAA(id, current_logged_user) {
    var headers = Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader())
    var types = this.createRSAATriplet('DATASET_DOWNLOAD_PRECHECK')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/items/${id}/validate/size?num=11`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  deleteDSItemRSSA(datasetId, form_data, current_logged_user, node) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('DELETE_DSITEM')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${datasetId}/items`,
        method: 'DELETE',
        body: form_data,
        headers,
        types: types
      }
    }
  }

  createFetchDSItemSchemaRSAA(id, current_logged_user) {
    var headers = Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader())
    var types = this.createRSAATriplet('FETCH_DATASET_ITEM_SCHEMA')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/details?num=11&expandable=true`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  deleteDSGroupRSSA(datasetId, form_data, current_logged_user) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('DELETE_DSGROUP')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${datasetId}/items/group`,
        method: 'DELETE',
        body: form_data,
        headers,
        types: types
      }
    }
  }

  createCreateDatasetGroupRSAA(params, current_logged_user) {
    var types = this.createRSAATriplet('CREATE_GROUP_DATASETS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${params.id}/items/group`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  moveDSItemToGroupRSSA(datasetId, form_data, destName, current_logged_user) {
    var types = this.createRSAATriplet('MOVE_DSITEM_GROUP')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${datasetId}/items/group/${destName}`,
        method: 'PUT',
        headers: this.authorizationHeader(),
        types,
        body: form_data
      }
    }
  }

  editDSSetSchemaRSSA(body, id, current_logged_user) {
    var types = this.createRSAATriplet('EDIT_DSET_SCHEMA')
    types = types.map(type => {
      return { type: type, meta: {} }
    })

    let js_body = {
      datasetSchema: {
        schema: body
      }
    }

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/schema`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify(js_body)
      }
    }
  }

  createFetchDatasetSchemaRSAA(id, current_logged_user) {
    var types = this.createRSAATriplet('FETCH_DATASET_SCHEMA')
    types = types.map(type => {
      return { type: type, meta: { id: id } }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${id}/fresh`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchQuickSettingsRSAA(id, current_logged_user, silent) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_APP_QUICK_SETTING' : 'FETCH_APP_QUICK_SETTING'
    var types = this.createRSAATriplet(_prefix)
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/quickSettings`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  deleteDSFolderRSSA(form_data, recursive, current_logged_user) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('DELETE_DSFOLDER')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/data/dataFolders?recursive=${recursive}`,
        method: 'DELETE',
        body: form_data,
        headers,
        types: types
      }
    }
  }

  moveDSFolderToFolderRSSA(form_data, current_logged_user) {
    var types = this.createRSAATriplet('MOVE_DSFOLDER_FOLDER')
    types = types.map(type => {
      return { type: type, meta: {} }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/data/datFolders/move`,
        method: 'PUT',
        headers: this.authorizationHeader(),
        types,
        body: form_data
      }
    }
  }

  moveDSSetToFolderRSSA(form_data, current_logged_user) {
    var types = this.createRSAATriplet('MOVE_DSET_FOLDER')
    types = types.map(type => {
      return { type: type, meta: {} }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/data/move?spaceId=${current_logged_user.spaceId}&orgId=${current_logged_user.orgId}`,
        method: 'PUT',
        headers: this.authorizationHeader(),
        types,
        body: form_data
      }
    }
  }

  createFetchMyModelsRSAA(params, current_logged_user) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''

    var types = this.createRSAATriplet('FETCH_MY_MODELS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/models${queryStr}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchAppDetailRSAA(id, silent, current_logged_user) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_APP_DETAIL' : 'FETCH_APP_DETAIL'

    var types = this.createRSAATriplet(_prefix)
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/details`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchMyAppsRSAA(params, appendToList, current_conf) {
    const defaultParams = { start: 0, max: 10 }
    const _params = params != null ? Object.assign(defaultParams, params) : defaultParams
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''

    var types = this.createRSAATriplet('FETCH_MY_APPS')
    types = types.map(type => {
      return { type: type, meta: { append: appendToList } }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/apps${queryStr}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  deleteAppRSSA(id, current_logged_user) {
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types: [
          'DELETE_APP_REQUEST',
          {
            type: 'DELETE_APP_SUCCESS',
            meta: { id: id }
          },
          'DELETE_APP_FAILURE'
        ]
      }
    }
  }

  createBuildAppTemplateRSAA(id, params, current_logged_user) {
    var types = this.createRSAATriplet('BUILD_APP_TEMPLATE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/templates/${id}/build`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  createFetchTemplatesRSAA(params, appendToList, current_logged_user) {
    const defaultParams = { start: 0, max: 10 }
    const _params = params != null ? Object.assign(defaultParams, params) : defaultParams
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''

    var types = this.createRSAATriplet('FETCH_TEMPLATES')
    types = types.map(type => {
      return { type: type, meta: { append: appendToList } }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/templates${queryStr}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createDeleteDeployAppRSAA(params, current_logged_user) {
    var types = this.createRSAATriplet('DELETE_DEPLOY_APP')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${params.appId}/deployments/${params.deployId}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: types
      }
    }
  }

  createDeployAppRSAA(params, current_logged_user) {
    var types = this.createRSAATriplet('DEPLOY_APP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${params.appId}/deploy`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  createTriggerAppUndockRSAA(id, current_logged_user) {
    var types = this.createRSAATriplet('TRIGGER_APP_UNDOCK')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/undock`,
        method: 'POST',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchUberDeploymentRSAA(orgId, silent, spaceId) {
    const _prefix = silent && silent !== undefined ? 'SI_FE_UB_DEPLOYMENT_STATUS' : 'FETCH_UBER_DEPLOYMENT_STATUS'
    const queryStr = spaceId !== null && spaceId !== undefined && spaceId !== 'undefined' ? `?projectId=${spaceId}` : ''

    var types = this.createRSAATriplet(_prefix)
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/uberDeployment${queryStr}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createUberDeploymentRSAA(params, orgId, projectId) {
    const queryStr =
      projectId !== null && projectId.projectId !== undefined && projectId.projectId !== 'undefined'
        ? `?${qs.stringify(projectId, { indices: false })}`
        : ''
    var types = this.createRSAATriplet('UBER_DEPLOY_APP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/uber-deployment${queryStr}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: params.body
      }
    }
  }

  createQuickSettingsRSAA(id, userParentId, params, current_logged_user) {
    let endpoint = ''
    if (userParentId !== null) {
      endpoint = `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/updateQuickSettings?parentId=${userParentId}`
    } else {
      endpoint = `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/updateQuickSettings`
    }

    var types = this.createRSAATriplet('APP_QUICK_SETTINGS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: endpoint,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify(params.body)
      }
    }
  }

  createFetchIterModelRSAA(id, iterId, current_logged_user) {
    var types = this.createRSAATriplet('FETCH_ITR_SAMPLE_MODEL')
    types = types.map(type => {
      return { type: type, meta: { iterId: iterId } }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/models/${id}/details`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchAppIterationDetailRSAA(id, silent, current_logged_user, params, appendToList) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''

    const _prefix = Boolean(silent) ? 'FETCH_APP_ITERATION_DETAIL_SILENTLY' : 'FETCH_APP_ITERATION_DETAIL_OPENLY'

    var types = this.createRSAATriplet(_prefix)
    types = types.map(type => {
      return { type: type, meta: { append: appendToList } }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/iteration${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createDatasetGetAutoMappingRSAA(params, current_logged_user) {
    var types = this.createRSAATriplet('GET_AUTO_MAPPINGS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV2RootUrl}/data/mapping?orgId=${current_logged_user.orgId}&spaceId=${current_logged_user.spaceId}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify(params.body)
      }
    }
  }

  createManualUberShutDownRSAA(orgId, projectId, forceShutDown) {
    const queryStr =
      projectId !== null && projectId !== undefined && projectId !== 'undefined' ? `?projectId=${projectId}` : ''
    var types = this.createRSAATriplet('UBER_MANUAL_SHUTDOWN')
    types = types.map(type => {
      return { type: type }
    })

    const params = forceShutDown.forceStop !== null ? `&${qs.stringify(forceShutDown, { indices: false })}` : ''

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/uber-deployment${queryStr}${params}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createFetchContainerMeteringDetailRSAA(orgId, params) {
    const _params = Object.assign(params)
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''

    var types = this.createRSAATriplet('GET_CONTAINER_METERING')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/container/metering${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createInvitationCodePreCheck(invitationCode) {
    var types = this.createRSAATriplet('INVITATION_CODE_PRECHECK')
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/project/profile/userRoles/invite/${invitationCode}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createFetchMyNewDatasetsRSAA(params, current_conf) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/datasets/list${queryStr}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types: ['FETCH_NEW_MY_DATASETS_REQUEST', 'FETCH_NEW_MY_DATASETS_SUCCESS', 'FETCH_NEW_MY_DATASETS_FAILURE']
      }
    }
  }

  createFetchVersionInfoRSAA(orgId) {
    var types = this.createRSAATriplet('FETCH_VERSION_INFO')
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/buildInfo?orgId=${orgId}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types: types
      }
    }
  }

  fetchAppResourcesRSAA(id, silent, current_logged_user) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_APP_SETTINGS' : 'FETCH_APP_SETTINGS'

    var types = this.createRSAATriplet(_prefix)
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/resources`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createFetchSettingsContentRSAA(id, resId, current_logged_user) {
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/resources/${resId}/raw`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: [
          'FETCH_SETTINGS_CONTENT_REQUEST',
          'FETCH_SETTINGS_CONTENT_SUCCESS',
          {
            type: 'FETCH_SETTINGS_CONTENT_FAILURE',
            meta: (action, state, res) => {
              if (res) {
                return {
                  status: res.status
                }
              } else {
                return {
                  status: null
                }
              }
            }
          }
        ]
      }
    }
  }

  uploadAppSettingsRSAA(id, form, current_logged_user) {
    var types = this.createRSAATriplet('UPLOAD_APP_SETTINGS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/resources`,
        method: 'POST',
        headers: this.authorizationHeader(),
        types,
        body: form
      }
    }
  }

  createStartAppRSAA(id, current_logged_user) {
    var types = this.createRSAATriplet('START_APP_JOB')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/start`,
        method: 'POST',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createStopAppRSAA(id, current_logged_user) {
    var types = this.createRSAATriplet('STOP_APP_JOB')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/stop`,
        method: 'POST',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createSaveAppScheduleRSAA(id, params, current_logged_user) {
    var types = this.createRSAATriplet('SAVE_APP_SCHEDULE')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/schedule`,
        method: 'PUT',
        body: params.body,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createSandboxRunRSAA(id, params, current_logged_user) {
    var types = this.createRSAATriplet('APP_SANDBOX_RUN')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${id}/sandbox`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: params.body,
        types
      }
    }
  }

  createSaveDeploymentProfileRSAA(params, current_logged_user) {
    const method = params.body.id == null ? 'POST' : 'PUT'
    var types = this.createRSAATriplet('SAVE_DEPLOYMENT_PROFILE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/deployment-profiles`,
        method: method,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: params.body,
        types
      }
    }
  }

  createDeleteDeploymentProfileRSAA(id, current_logged_user) {
    var types = this.createRSAATriplet('DELETE_DEPLOYMENT_PROFILE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/deployment-profiles/${id}`,
        method: 'DELETE',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createFetchMySubscriptionPlanUsageRSAA(current_logged_user) {
    var types = this.createRSAATriplet('FETCH_SUBSCRIPTION_USAGE')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/subscription_usage`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  createSaveCotObjectRSAA(datasetId, datasetName, params, current_logged_user) {
    var types = this.createRSAATriplet('SAVE_COT_OBJECT')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/cot?name=${datasetName}&datasetId=${datasetId}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(params),
        types
      }
    }
  }

  fetchSavedCotObjectRSAA(datasetId, current_conf) {
    var types = this.createRSAATriplet('FETCH_SAVEDCOT_OBJECT')
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/cotAll?datasetId=${datasetId}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  fetchPermissionMatrixObjectRSAA(current_logged_user) {
    const queryStr =
      current_logged_user !== null &&
        current_logged_user.spaceId !== undefined &&
        current_logged_user.spaceId !== 'undefined'
        ? `?${qs.stringify({ projectId: current_logged_user.spaceId }, { indices: false })}`
        : ''
    var types = this.createRSAATriplet('FETCH_PERMISSION_MATRIX')
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project_matrix${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  fetchPaymentReceiptsRSAA(current_logged_user, paymentId) {
    var types = this.createRSAATriplet('FETCH_PAYMENT_RECEIPTS')
    types = types.map(type => {
      return { type: type }
    })
    const paymentParam = paymentId ? `&startingAfter=${paymentId}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/paymentReceipts?limit=20${paymentParam}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  pingMiServiceRSAA(modelId, frameWork, current_logged_user) {
    var types = this.createRSAATriplet('PING_MISERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/interpretability?modelId=${modelId}&framework=${frameWork}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify({ interpretabilityContainerSize: { memoryMBs: 0, instances: 0, cpuCores: 0 } }),
        types
      }
    }
  }

  fetchmodelDatasetCountRSAA(current_logged_user, projectId) {
    var types = this.createRSAATriplet('FETCH_MDCOUNT')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${projectId}/modelDatasetCount`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }
  fetchSelectedProjectsStatsInfoRSAA(orgId, projectId) {
    var types = this.createRSAATriplet('FETCH_SELECTED_PROJECT_INFO_STATS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/project/${projectId}/details`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }
  fetchUserRolesRSAA(current_logged_user, projectId) {
    var types = this.createRSAATriplet('FETCH_USER_ROLES')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${projectId}/profile/userRoles`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  fetchUserActionsRSAA(current_logged_user, projectId) {
    var types = this.createRSAATriplet('FETCH_USER_ACTIONS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${projectId}/useractions`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }
  getCurrentRolesRSAA(current_logged_user) {
    var types = this.createRSAATriplet('GET_USER_ROLES')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/roles?org=${current_logged_user.orgId}&projectId=${current_logged_user.spaceId}`,
        method: 'GET',
        headers: this.authorizationHeader(),
        types
      }
    }
  }

  deleteUserRSAA(currentOrg, currentSpace, removeUserRole) {
    var types = this.createRSAATriplet('DELETE_USER_FROM_PROJECT')
    types = types.map(type => {
      return { type: type, meta: {} }
    })

    const spaceId = currentSpace ? (currentSpace.id ? currentSpace.id : currentSpace.projectId) : null
    const orgId = currentOrg.id ? currentOrg.id : currentOrg.orgId
    const userId =
      removeUserRole.user && removeUserRole.user.id
        ? removeUserRole.user.id
        : removeUserRole.user && removeUserRole.user.email
          ? removeUserRole.user.email
          : removeUserRole.email

    const queryStr = spaceId
      ? `?${qs.stringify({ projectId: spaceId, userId: userId }, { indices: false })}`
      : `?${qs.stringify({ userId: userId }, { indices: false })}`

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/user${queryStr}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }
  deleteProjectRSAA(current_logged_user, spaceForDeletion) {
    var types = this.createRSAATriplet('DELETE_PROJECT')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${spaceForDeletion}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  updateProjectObjectivesStatusRSAA(current_logged_user, currentSpace, objectiveId, object) {
    var types = this.createRSAATriplet('UPDATE_PROJECT_OBJECTIVE')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${currentSpace.id}/objective/${objectiveId}`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: object
      }
    }
  }
  updateUserRoleRSAA(currentOrg, currentSpace, newRole, userObj, permissions) {
    var types = this.createRSAATriplet('UPDATE_USER_ROLE')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${currentOrg.id}/project/${currentSpace.id}/role/${newRole}/user/${userObj.user.id}`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify({ permissions: permissions })
      }
    }
  }

  //For USER PAGE CALLS
  getCurrentUsersOrganizationRSAA(orgId) {
    var types = this.createRSAATriplet('FETCH_USERS_ORG')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/users`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  getCurrentRolesOfUsersRSAA(current_logged_user) {
    var types = this.createRSAATriplet('FETCH_ALL_USER_ROLES')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/roles?org=${current_logged_user.orgId}&projectId=${current_logged_user.spaceId}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  getCurrenttContextRSAA(value) {
    var types = this.createRSAATriplet('GET_CURRENTCONTEXT')
    types = types.map(type => {
      return { type: type }
    })
    return {
      type: 'GET_CURRENTCONTEXT_SUCCESS',
      orgDetails: value
    }
  }

  startSuperSetServiceRSAA(current_logged_user) {
    var types = this.createRSAATriplet('START_SUPERSET_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = getSpaceId(current_logged_user, 'projectId')

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/superset/start${queryStr}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(window.REACT_APP_CONTAINER_RESOURCES),
        types
      }
    }
  }

  stopSuperSetServiceRSAA(current_logged_user) {
    var types = this.createRSAATriplet('STOP_SUPERSET_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = getSpaceId(current_logged_user, 'projectId')

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/superset/stop${queryStr}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  startMicroServiceRSAA(current_logged_user, details) {
    var types = this.createRSAATriplet('START_MICRO_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/microservice?modelId=${details.modelId}&framework=${details.frameWork}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify({ interpretabilityContainerSize: { memoryMBs: 0, instances: 0, cpuCores: 0 } }),
        types
      }
    }
  }

  stopMicroServiceRSAA(current_logged_user, details) {
    var types = this.createRSAATriplet('STOP_MICRO_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/microservice?modelId=${details.modelId}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  getMicroServiceModalMetaRSAA(current_logged_user, details) {
    var types = this.createRSAATriplet('FETCH_MICRO_SERVICE_META')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/microservice/${details.modelId}/model_meta`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  addUserToProjectSupersetRSAA(current_logged_user) {
    var types = this.createRSAATriplet('ADD_USER_TO_PROJECT_SUPERSET')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/add/user`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  deleteUserFromProjectSupersetRSAA(current_logged_user) {
    var types = this.createRSAATriplet('DELETE_USER_FROM_SUPERSET_PROJECT')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/remove/user`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createDashboardSupersetRSAA(current_logged_user, formData) {
    var types = this.createRSAATriplet('CREATE_DASHBOARD_SUPERSET')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/createDashboard`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        body: formData,
        types
      }
    }
  }

  getDashboardSupersetRSAA(current_logged_user) {
    var types = this.createRSAATriplet('GET_DASHBOARD_SUPERSET')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/dashboards?startPage=0&pageSize=10`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  deleteDashboardSupersetRSAA(current_logged_user, dashboardId) {
    var types = this.createRSAATriplet('DELETE_DASHBOARD_SUPERSET')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/dashboard?dashboardId=${dashboardId}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createChartSupersetRSAA(current_logged_user, formData) {
    var types = this.createRSAATriplet('CREATE_CHART_SUPERSET')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/emlyViz/createChart`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        body: formData,
        types
      }
    }
  }

  createExplorerGenerateViewRSAA(tableId, current_logged_user, pageSize, offset, filters) {
    var types = this.createRSAATriplet('CAYLEY_PAGE_VIEW')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId
          }/datasets/explorePage?tableId=${tableId}&noOfSampleRecord=${pageSize}&offset=${offset}&filters=${JSON.stringify(
            filters
          )}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createDatasetLockRSAA(current_logged_user, conf, lockExpireTime) {
    var types = this.createRSAATriplet('LOCK_DATASET_TYPE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = lockExpireTime != null ? `&${qs.stringify(lockExpireTime, { indices: false })}` : ''

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasets/${conf.datasetId}/lock?lockType=${conf.lockType}${queryStr}`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createFetchSuperSetStatusRSAA(current_logged_user) {
    var types = this.createRSAATriplet('STATUS_SUPERSET_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/superset/status`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  deleteDssServiceRSAA(current_logged_user) {
    var types = this.createRSAATriplet('DELETE_DATASET_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/uber_datasummary`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  deleteMIServiceRSAA(current_logged_user, modelId) {
    var types = this.createRSAATriplet('DELETE_MI_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/interpretability?modelId=${modelId}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchMicroServiceStatusRSAA(modelId, current_logged_user) {
    var types = this.createRSAATriplet('STATUS_MICRO_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/model/${modelId}/microservice`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createFetchDssStatusRSAA(current_logged_user) {
    var types = this.createRSAATriplet('STATUS_DSS_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/datasummary/status`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createFetchStartDssRSAA(current_logged_user) {
    var types = this.createRSAATriplet('START_DSS_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/uber_datasummary`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader(), { 'Content-Type': 'application/json' }),
        body: JSON.stringify(window.REACT_APP_CONTAINER_RESOURCES),
        types
      }
    }
  }
  createFetchMiServiceStatusRSAA(modelId, current_logged_user) {
    var types = this.createRSAATriplet('STATUS_MI_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/model/${modelId}/interpret`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createFetchCayleyDistributionDataRSAA(current_logged_user, datasetId, _params, tableId) {
    var types = this.createRSAATriplet('FETCH_CAYLEY_DISTRIBUTION')
    types = types.map(type => {
      return { type: type }
    })

    const isTableId = tableId != null ? `?${qs.stringify(tableId, { indices: false })}` : ''

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/dataset/${datasetId}/distribution${isTableId}`,
        method: 'POST',
        body: _params,
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  createCayleyDistributionDataPollRSAA(requestId, org) {
    var types = this.createRSAATriplet('POLL_FETCH_CAYLEY_DISTRIBUTION')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/request/${requestId}/distributionStatus`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  getAllDefaultConfigRulesOfOrgRSAA(current_logged_user) {
    var types = this.createRSAATriplet('ALL_ORG_CONFIG_RULES')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/allOrgConfigs`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  updateOrgConfigsRSAA(current_logged_user, updateObjectList = []) {
    var types = this.createRSAATriplet('UPDATE_ORG_CONFIG')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/orgConfiguration/updateOrgConfigs`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify({ configurations: updateObjectList })
      }
    }
  }
  resetOrgConfigsRSAA(current_logged_user) {
    var types = this.createRSAATriplet('RESET_ORG_COFIGS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/orgConfiguration/resetOrgConfigs`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createAppRecomommendationRSAA(current_logged_user, appId, data) {
    var types = this.createRSAATriplet('GET_APP_RECOMMENDATIONS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/apps/${appId}/appRecommendations`,
        method: 'POST',
        body: data,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }
  getDatasetSummaryRSAA(current_logged_user, asyncPath, tab, data) {
    var types = this.createRSAATriplet('GET_DATASET_SUMMARY')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/result/uber_datasummary?path=${asyncPath}${tab}`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader(), { 'Content-Type': 'application/json' }),
        body: JSON.stringify(data),
        types
      }
    }
  }
  getOrgSubscriptionCheckRSAA(current_logged_user) {
    var types = this.createRSAATriplet('GET_SUBSCRIPTION_CHECK')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/subscriptionCheck`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  getProjectCreationFlowsRSAA(url) {
    var types = this.createRSAATriplet('GET_PROJECT_FLOWS')
    const timestamp = `?time=${new Date().toISOString()}`

    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${url}${timestamp}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        types
      }
    }
  }

  getOrgSpecficProjectCreationFlowsRSAA(url) {
    var types = this.createRSAATriplet('GET_ORGPROJECT_FLOWS')
    const timestamp = `?time=${new Date().toISOString()}`

    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${url}${timestamp}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        types
      }
    }
  }

  createDeleteProjectRSAA(projectId, current_logged_user) {
    var types = this.createRSAATriplet('DELETE_PROJECT_OF_ORG')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${projectId}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createDatasetExplorerInitRSAA(datasetId, org, lockObject, pageSize) {
    var types = this.createRSAATriplet('FETCH_EXPLORE_INIT')
    types = types.map(type => {
      return { type: type }
    })

    let lockKey = ''
    if (lockObject.length > 0) lockKey = `lockKey=${lockObject[0].lock.lockKey}`

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/datasets/${datasetId}/exploreInIt?${lockKey}&noOfSampleRecord=${pageSize}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createDatasetExplorerExecuteRSAA(_params, org, lockObject, pageSize) {
    var types = this.createRSAATriplet('FETCH_EXPLORE_EXECUTE')
    types = types.map(type => {
      return { type: type }
    })

    let lockKey = ''
    if (lockObject.length > 0) lockKey = `?lockKey=${lockObject[0].lock.lockKey}`

    return {
      [RSAA]: {
        endpoint: `${this.apiV4RootUrl}/org/${org.orgId}/project/${org.spaceId}/exploreExecute${lockKey}&noOfSampleRecords=${pageSize}`,
        method: 'POST',
        body: _params.body,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: types
      }
    }
  }

  createDatasetExplorerPollRSAA(requestId, org) {
    var types = this.createRSAATriplet('FETCH_EXPLORE_POLL')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/request/${requestId}/exploreFetchExecute`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createDatasetExplorerSaveRSAA(_params, queryStr, org) {
    var types = this.createRSAATriplet('FETCH_EXPLORE_SAVE')
    types = types.map(type => {
      return { type: type }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV4RootUrl}/org/${org.orgId}/project/${org.spaceId}/exploreFinalize${queryStr}`,
        method: 'POST',
        body: _params.body,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: types
      }
    }
  }

  createTasksRSAA(current_conf) {
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_conf.orgId}/project/${current_conf.projectId}/offlineNotifications`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: ['FETCH_STORED_SSE_JOBS_REQUEST', 'FETCH_STORED_SSE_JOBS_SUCCESS', 'FETCH_STORED_SSE_JOBS_FAILURE']
      }
    }
  }

  createCheckOutSessionRSAA(current_logged_user, params) {
    var types = this.createRSAATriplet('FETCH_CHECKOUT_SESSION')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/checkoutSession`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(params),
        types
      }
    }
  }

  createUserOrganizationRSAA(params) {
    var types = this.createRSAATriplet('CREATE_USER_ORGANIZATION')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(params),
        types
      }
    }
  }

  createDatasetUploadPollRSAA(requestId, org) {
    var types = this.createRSAATriplet('FETCH_UPLOAD_POLL')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/requestId/${requestId}/uploadStatus`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createDatasetUploadMaxSizeRSAA(org) {
    var types = this.createRSAATriplet('FETCH_DATASET_MAX_UPLOAD')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/allowedDatasetSize`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  createFetchMyDatasetsRSAA(org, params) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/datasetList${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: ['FETCH_MY_DATASETS_REQUEST', 'FETCH_MY_DATASETS_SUCCESS', 'FETCH_MY_DATASETS_FAILURE']
      }
    }
  }

  createfetchValidateDatasetRSAA(org, modelId, datasetId) {
    var types = this.createRSAATriplet('FETCH_SCHEMA_VALIDATE_DS')
    types = types.map(type => {
      return { type: type }
    })
    const queryStr = datasetId != null ? `?${qs.stringify({ datasetId: datasetId }, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${org.orgId}/project/${org.spaceId}/model/${modelId}/validateAppModelWithDataset${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: types
      }
    }
  }

  startJupyterServiceRSAA(current_logged_user) {
    var types = this.createRSAATriplet('START_JUPYTER_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = current_logged_user.spaceId
      ? `?${qs.stringify({ projectId: current_logged_user.spaceId }, { indices: false })}`
      : ``

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/jupyter/deployment/start${queryStr}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify({}),
        types
      }
    }
  }

  createFetchJupyterStatusRSAA(current_logged_user) {
    var types = this.createRSAATriplet('STATUS_JUPYTER_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = current_logged_user.spaceId
      ? `?${qs.stringify({ projectId: current_logged_user.spaceId }, { indices: false })}`
      : ``

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/jupyter/deployment/status${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types
      }
    }
  }

  stopJupyterServiceRSAA(current_logged_user) {
    var types = this.createRSAATriplet('STOP_JUPYTER_SERVICE')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = current_logged_user.spaceId
      ? `?${qs.stringify({ projectId: current_logged_user.spaceId }, { indices: false })}`
      : ``

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/jupyter/deployment/stop${queryStr}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  startGenAIChat(appId, body, current_logged_user) {
    var types = this.createRSAATriplet('START_CHAT_GENAI')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/app/${appId}/chat`,
        method: 'POST',
        headers: Object.assign({ accept: '*/*', 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  getProjectGroupsRSAA() {
    var types = this.createRSAATriplet('GET_PROJECT_GROUPS')
    types = types.map(type => {
      return { type: type }
    })

    const timestamp = `?time=${new Date().toISOString()}`

    return {
      [RSAA]: {
        endpoint: `${window.REACT_APP_GROUPIDS}${timestamp}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        types
      }
    }
  }

  createProjectFlowRSAA(orgId, body) {
    var types = this.createRSAATriplet('CREATE_A_NEW_PROJECT')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/createProject`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createListGroupsRSAA(current_logged_user, pageRecords, type) {
    var types = this.createRSAATriplet('GET_LIST_GROUPS')
    types = types.map(type => {
      return { type: type }
    })

    const spaceId =
      current_logged_user !== null &&
        current_logged_user.spaceId !== undefined &&
        current_logged_user.spaceId !== null &&
        current_logged_user.spaceId !== 'undefined'
        ? current_logged_user.spaceId
        : null

    const queryStr = pageRecords
      ? `?${qs.stringify(
        { start: pageRecords.start, max: pageRecords.max, ...(spaceId && { projectId: spaceId }), ...(type && { type: type }) },
        { indices: false }
      )}`
      : ``

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/groups${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createGroupRSAA(current_logged_user, body) {
    var types = this.createRSAATriplet('CREATE_A_NEW_GROUP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/create`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createGetGroupRSAA(current_logged_user, groupId) {
    var types = this.createRSAATriplet('GET_GROUP_DETAILS')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = getSpaceId(current_logged_user, 'projectId')

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/${groupId}${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createDeleteGroupRSAA(current_logged_user, body) {
    var types = this.createRSAATriplet('DELETE_GROUP_DETAILS')
    types = types.map(type => {
      return { type: type }
    })

    const queryStr = getSpaceId(current_logged_user, 'projectId')

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/groups/delete${queryStr}`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createAddGroupEntityRSAA(current_logged_user, groupId, body) {
    var types = this.createRSAATriplet('ADD_GROUP_ENTITY')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/${groupId}/member`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createDeleteGroupEntityRSAA(current_logged_user, groupId, body) {
    var types = this.createRSAATriplet('DELETE_GROUP_ENTITY')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/${groupId}/member`,
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createGetGroupMembersRSAA(current_logged_user, groupId, type, pageRecords) {
    const spaceId =
      current_logged_user !== null &&
        current_logged_user.spaceId !== undefined &&
        current_logged_user.spaceId !== null &&
        current_logged_user.spaceId !== 'undefined'
        ? current_logged_user.spaceId
        : null

    const queryStr = pageRecords
      ? `?${qs.stringify(
        { start: pageRecords.start, max: pageRecords.max, ...(spaceId && { projectId: spaceId }) },
        { indices: false }
      )}`
      : ``

    var types = this.createRSAATriplet('GET_GROUP_MEMBERS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/${groupId}/type/${type}/members${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createGetOrgGroupsAllProjectsRSAA(current_logged_user) {
    var types = this.createRSAATriplet('GET_ORG_ENTITY_GROUPS')
    types = types.map(type => {
      return { type: type }
    })
    const spaceId =
      current_logged_user !== null &&
        current_logged_user.spaceId !== undefined &&
        current_logged_user.spaceId !== null &&
        current_logged_user.spaceId !== 'undefined'
        ? current_logged_user.spaceId
        : null

    var queryStr = `?type=project&visibility=true`
    if (spaceId) queryStr = queryStr + `&projectId=${spaceId}`

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/group/all/members${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createNewUserRSAA(current_logged_user, body) {
    var types = this.createRSAATriplet('REGISTER_A_NEW_USER')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/users/create`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createInviteUserToProjectRSAA(body, orgId, spaceId) {
    var types = this.createRSAATriplet('INVITE_A_NEW_USER')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${orgId}/users/invite?projectId=${spaceId}`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  getAllProjectsOfOrgRSAA(current_logged_user) {
    var types = this.createRSAATriplet('GET_ALL_ORG_PROJECTS')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/user/projects`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createCompleteOnboardingRSAA(body) {
    var types = this.createRSAATriplet('COMPLETE_USER_ONBOARDING')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/complete/onboarding`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        body: JSON.stringify(body),
        types
      }
    }
  }

  createStartGAIServiceRSAA(current_logged_user, appId) {
    var types = this.createRSAATriplet('DEPLOY_GENAI_SERVICE_APP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/job/${appId}/service/genai`,
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchGAIServiceRSAA(current_logged_user, appId) {
    var types = this.createRSAATriplet('FETCH_GENAI_SERVICE_APP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/job/${appId}/service/genai`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createDeleteGAIServiceRSAA(current_logged_user, appId) {
    var types = this.createRSAATriplet('STOP_GENAI_SERVICE_APP')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/job/${appId}/service/genai`,
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createUpdateProjectStatusRSAA(current_logged_user, id, body) {
    var types = this.createRSAATriplet('UPDATE_OBJECTIVE_PROJECT')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/project/${current_logged_user.spaceId}/objective/${id}/updateObjective`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify(body)
      }
    }
  }


  createUpdateBotConfigurationRSAA(url, body) {
    var types = this.createRSAATriplet('BOT_SAVE_CONFIGURATION')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: url,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: body
      }
    }
  }

  createGetBotConfigurationRSAA(url) {
    var types = this.createRSAATriplet('BOT_GET_CONFIGURATION')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
      }
    }
  }

  createUpdateSpecialPermissionRSAA(current_logged_user, userId, body) {
    const queryStr = getSpaceId(current_logged_user, 'spaceId')
    var types = this.createRSAATriplet('UPDATE_SPECIAL_PERMISSION')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: `${this.apiV3RootUrl}/org/${current_logged_user.orgId}/user/${userId}/specialPermissions${queryStr}`,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
        body: JSON.stringify(body)
      }
    }
  }

  createFetchAppStoreConfigurationRSAA() {
    var types = this.createRSAATriplet('FETCH_APPSTORE_CONFIG')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: 'https://storage.googleapis.com/17d84a62bfae0c05e940e9d8668ed0029429c724/public/ui_assets/app_templates/app_templatels.json',
        method: 'GET',
        types
      }
    }
  }

  createFetchBotCapabilitiesRSAA() {
    var types = this.createRSAATriplet('BOT_GET_CAPABILITIES')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: window.REACT_APP_CAPABILITIES,
        method: 'GET',
        types
      }
    }
  }

  createFetchBotFormTriggersRSAA() {
    var types = this.createRSAATriplet('BOT_GET_FORM_TRIGGERS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: window.REACT_APP_TRIGGERS,
        method: 'GET',
        types
      }
    }
  }

  createFetchBotActionFormsRSAA() {
    var types = this.createRSAATriplet('BOT_GET_ACTION_FORMS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: window.REACT_APP_BOT_FORMS,
        method: 'GET',
        types
      }
    }
  }

  createFetchRefreshBotDataRSAA(url) {
    var types = this.createRSAATriplet('BOT_REFRESH_IMPORT_DATA')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: url,
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchRefreshBotDataStatusRSAA(url) {
    var types = this.createRSAATriplet('BOT_REFRESH_IMPORT_STATUS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }

  createFetchBotReportsRSAA(url) {
    var types = this.createRSAATriplet('BOT_GET_REPORT_DATA')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    return {
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types,
      }
    }
  }

  createFetchBotSummaryRSAA(url, body) {

    console.log('createFetchBotSummaryRSAA', body)
    var types = this.createRSAATriplet('BOT_GET_REPORT_SUMMARY')
    types = types.map(type => {
      return { type: type }
    })

    return {
      [RSAA]: {
        endpoint: url,
        body: JSON.stringify(body),
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types
      }
    }
  }
}
