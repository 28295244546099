import $ from 'jquery'
import React, { useEffect, useRef } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ReactGA from 'react-ga4'
import Recaptcha from 'react-google-invisible-recaptcha'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Select from 'react-select'
import { getGoogleLink, getlinkedinLink } from '../../../KeyCloak/googleSignup'
import {
  countries,
  initializeReactGoogle,
  notifyGoogle,
  splitStringAndGetLastElement
} from '../../components-examples/utils/commonUtilityFunctions'
import PopUp from '../PopUP'
import { NormalButton } from '../ReusableCode/EmlyButton'
import { LoaderBGWithinComponent } from '../utils'
import './index.css'
import { SignUpLeft } from './SignUpLeft'
import { SignUpLeftLearner } from './SignUpLeftLearner'

initializeReactGoogle({})

const signUpSuccess = emailId => {
  return (
    <div className="em-row em-flex-success">
      <h1 className="register verify">Verify Your Profile </h1>
      <span className="left">
        An email has been sent to your email address <span className="bd">{emailId}</span>
      </span>

      <span className="left">Check Inbox &gt; Open Email &gt; Follow Instructions</span>
      <div className="email-verify-note">
        <span className="bd">Important:</span> Check Spam, If not in inbox, check your spam/junk folder. For help,
        contact{' '}
        <a className="bd" href="mailto:support@emlylabs.com">
          support@emlylabs.com
        </a>
        .
      </div>
    </div>
  )
}

const ApiStatus = ({ button = false, message, image, linkText = false, goback, url, errorMessage }) => {
  return (
    <div className="error-div">
      {image !== 'IconSuccess' && (
        <img
          src={require(`../../../images/SignupImages/${image}.png`)}
          style={{ width: '20rem', height: '20rem', display: 'flex', alignSelf: 'center' }}
        />
      )}
      {image === 'IconSuccess' && <h1>Verify your profile.</h1>}
      <p className="sorry">
        {errorMessage ? errorMessage : message} <br />
        {image === 'IconSuccess' && <> Please check your email for further instructions.</>}
        {image === 'IconWarning' && (
          <>
            {' '}
            Note: If you are unable to redirect <a href={url}>Click Here</a>.
          </>
        )}
      </p>
      {button && (
        <button className="em-btn goback-button" onClick={goback}>
          Go Back
        </button>
      )}
    </div>
  )
}

const ShowApiStatus = ({ success, error, errorMessage, alreadyUser, goback, url, userEmail }) => {
  if (error) {
    return (
      <ApiStatus
        image={'IconError'}
        button={true}
        goback={goback}
        url={url}
        errorMessage={errorMessage}
        message={`Failed to signup due to input errors, please check your inputs and try again`}
      />
    )
  } else {
    if (success) {
      return signUpSuccess(userEmail)
    } else {
      if (alreadyUser) {
        return (
          <ApiStatus
            image={'IconWarning'}
            button={false}
            errorMessage={errorMessage}
            url={url}
            linkText={true}
            message={`You have already signed up on EmlyLabs, redirecting to SignIn.`}
          />
        )
      } else {
        return <div />
      }
    }
  }
}

const index = props => {
  const [planStatus, setPlanStatus] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [success, setSuccess] = React.useState(false)
  const [alreadyUser, setAlreadyUser] = React.useState(false)
  const [percentage, setPercentage] = React.useState('0%')
  const [invitationCode, setInvitationCode] = React.useState(null)
  const [showTandC, setShowTandC] = React.useState(false)
  const [showTandCLoader, setShowTandCLoader] = React.useState(true)
  const [bListEmails, setbListEmails] = React.useState([])
  const [userEmail, setUserEmail] = React.useState('')
  const [isLearner, setIsLearner] = React.useState(false)
  const [addon_prod_title, setAddon_prod_title] = React.useState(null)

  const iframeRef = useRef(null)

  const addHiddenFields = () => {
    ReactGA.send({ hitType: 'pageview', page: props.location.pathname + props.location.search, title: 'User Signup' })
    const params = new URLSearchParams(props.location.search)
    const invitationcode = params.get('invitationCode')
    const productId = params.get('prodId')
    const may_buy_addon_prodId = params.get('may_buy_addon_prodid')
    const addon_prodtitle = params.get('addon_prod_title')

    setInvitationCode(invitationcode)
    if (productId !== null && productId !== undefined) {
      const isLearner = window.REACT_APP_PLAN_LIST && window.REACT_APP_PLAN_LIST.map(item => {
        if (item.planId === productId && item.name && item.name.toUpperCase() === 'LEARNER') {
          setIsLearner(true)
          return item
        }
      })
    }

    if (invitationcode !== null && invitationcode !== undefined) {
      document.getElementById('invitationCode').value = invitationcode
    } else if (productId !== null && productId !== undefined) {
      setPlanStatus(productId)
      document.getElementById('productId').value = productId
    } else {
      window.location.href = window.REACT_APP_PRICING_LINK
    }
    if (productId !== null && may_buy_addon_prodId !== undefined) {
      document.getElementById('may_buy_addon_prodid').value = may_buy_addon_prodId
    }
    if (addon_prodtitle !== null && addon_prodtitle !== undefined) {
      setAddon_prod_title(addon_prodtitle)
    }
  }

  const getProdId = () => {
    const params = new URLSearchParams(props.location.search)
    const productId = params.get('prodId')
    if (productId !== null && productId !== undefined) return productId
    else return null
  }

  const getAddonProdId = () => {
    const params = new URLSearchParams(props.location.search)
    const productId = params.get('may_buy_addon_prodid')
    if (productId !== null && productId !== undefined) return productId
    else return null
  }

  useEffect(() => {
    if (!error) {
      addHiddenFields()
    }
  }, [error])

  useEffect(() => {
    notifyGoogle('signup_started')
    addHiddenFields()
    fetchBLDomains()
  }, [])

  const fetchBLDomains = () => {
    const fetchurl = window.REACT_APP_BLISTDOMAINS
    fetch(fetchurl, {
      method: 'GET'
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        setbListEmails(response)
      })
      .catch(err => {
        console.log('Error Occured while fetching the BlackListDomains File', err)
      })
  }

  const reCaptchaRef = React.useRef()

  const planData = window.REACT_APP_PLAN_LIST

  const handleformsubmit = event => {
    event.preventDefault()
    onSubmit(event)
  }

  const onSubmit = event => {
    const params = new URLSearchParams(props.location.search)
    const showEmail = params.get('invitationCode')

    if (showEmail === null) {
      var countryCode = document.getElementsByName('countryCode')[0].value
      if (countryCode === '' || countryCode === null || countryCode === undefined) {
        $('#f-country').parent().addClass('hasError')
        $('#sgp-country-validation-msg span').html('*Please provide country name')
        event.preventDefault()
        return false
      } else {
        $('#f-country').parent().removeClass('hasError')
        $('#sgp-country-validation-msg span').html('')
      }
      setUserEmail($('#f-email').val())
      var businessMail = $('#f-email').val()
      var businessMailDom = businessMail.split('@')
      if (bListEmails && bListEmails.indexOf(businessMailDom[1]) !== -1) {
        $('#f-email').parent().addClass('hasError')
        $('#sgp-email-validation-msg span').html(
          '*This email domain is not supported if you think it is a mistake please drop an email to support@emlylabs.com'
        )
        event.preventDefault()
        return false
      } else {
        $('#f-email').parent().removeClass('hasError')
        $('#sgp-email-validation-msg span').html('')
      }
    }

    if (event && event === 'RECAPTCHA') finalSubmit(event)
    else setShowTandC(true)
  }

  const finalSubmit = event => {
    setShowTandC(false)
    submitNow(event)
  }

  const submitNow = event => {
    setErrorMessage(null)
    var formData = $('#signUpForm').serialize()

    try {
      const f_name = document.getElementById('f-firstName').value
      const [restOfString, lastWord] = splitStringAndGetLastElement(f_name)
      formData = formData.replace(/firstName=[^&]*/, 'firstName=' + restOfString)
      formData = formData.replace(/lastName=[^&]*/, 'lastName=' + lastWord)
      if (isLearner === true) {
        document.getElementById('f-orgName').value = `${f_name.trim()} Org`
      }

    } catch (error) {
      console.log('Errror while setting last name', error)
    }

    var captchaVal = $('textarea[name="g-recaptcha-response"]').val()

    if (captchaVal.length == 0) {
      reCaptchaRef.current.execute()
      event.preventDefault()
      return false
    }

    $('#signUpForm').hide()
    $('#planStatus').hide()
    setIsLoading(true)
    $.ajax({
      xhr: function () {
        var xhr = new window.XMLHttpRequest()
        xhr.addEventListener(
          'progress',
          e => {
            var done = e.position || e.loaded,
              total = e.totalSize || e.total
            setPercentage(Math.floor((done / total) * 1000) / 10)
          },
          false
        )
        if (xhr.upload) {
          xhr.upload.onprogress = e => {
            var done = e.position || e.loaded,
              total = e.totalSize || e.total
            setPercentage(Math.floor((done / total) * 1000) / 10)
          }
        }

        return xhr
      },
      url: `${window.REACT_APP_GATEWAY_API_URL}/v4/signup`,
      type: 'POST',
      data: formData,
      crossDomain: true,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 201) {
          notifyGoogle('signup_completed')
          setSuccess(true)
        } else if (xhr.status === 200) {
          setAlreadyUser(true)
          if (data.message && data.message.trim().startsWith('http')) {
            setTimeout(function () {
              window.location = data.message.trim()
            }, 5000)
          }
        } else {
          $('#signUpStatus').html('<div className="alert alert-primary" role="alert">Successfully signed up!</div>')
        }
        $('#signUpForm').hide()
        $('#planStatus').hide()
        setIsLoading(false)
      },
      error: function (data, textStatus, xhr) {
        if (xhr.status === 412) {
          setError(true)
        } else if (data.status === 400) {
          setAlreadyUser(true)
        } else {
          setError(true)
        }
        if (data.responseJSON && data.responseJSON.message) {
          setErrorMessage(data.responseJSON.message)
        }
        setIsLoading(false)
        notifyGoogle('SIGNUP_FAILED')
      }
    })
  }

  const updateTandC = () => {
    setShowTandC(!showTandC)
  }

  const handleIframeLoad = () => {
    setTimeout(() => {
      setShowTandCLoader(false)
    }, 500)
  }

  return (
    <div className="datoin-signup">
      {showTandC && (
        <PopUp
          onCancel={() => updateTandC()}
          onExecution={event => finalSubmit(event)}
          buttonLabel={'I accept terms and conditions'}
          width={'900px'}
          bottomButton={showTandCLoader ? false : true}
          bottomButtonCancel={false}
          title=""
        >
          <div className="em-row" style={{ padding: '20px 0 0 0' }}>
            {showTandCLoader && <LoaderBGWithinComponent />}
            <iframe
              ref={iframeRef}
              id="emlyTandCiframe"
              onLoad={handleIframeLoad}
              frameBorder="0"
              height="450px"
              width="100%"
              src={'https://emlylabs.com/platform-terms-and-conditions/'}
            />
          </div>
        </PopUp>
      )}
      <div className="u-singup-div">
        <div className="image-div">
          {isLearner === true ?
            <SignUpLeftLearner invitationCode={invitationCode} />
            :
            <SignUpLeft invitationCode={invitationCode} />
          }
        </div>
        <div className="Text-component">
          {/* <Header url={window.REACT_APP_SELF_URL} /> */}
          <div className="emlyLogoMb">
            <img src={require('../../../images/onBoard/Elogo.png')} />
          </div>
          <div className="main">
            <ShowApiStatus
              error={error}
              success={success}
              alreadyUser={alreadyUser}
              errorMessage={errorMessage}
              url={window.REACT_APP_SELF_URL}
              goback={() => {
                setError(false)
              }}
              userEmail={userEmail}
            />
            {!error && !alreadyUser && !success && (
              <>
                {!isLoading && addon_prod_title !== null &&
                  <p className='learner-label-title'>{addon_prod_title}</p>
                }
                <Form
                  handleformsubmit={handleformsubmit}
                  planStatus={planStatus}
                  isLoading={isLoading}
                  planData={planData}
                  onSubmit={onSubmit}
                  invitationCode={invitationCode}
                  reCaptchaRef={reCaptchaRef}
                  percentage={percentage}
                  getProdId={getProdId}
                  getAddonProdId={getAddonProdId}
                  isLearner={isLearner}
                />
              </>
            )}
          </div>
          {!error && !alreadyUser && !success && !isLoading && (
            <a href="https://emlylabs.com/privacy-policy/" target="_blank" className="em-link privacy">
              Privacy Policy
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(state => state)(index))

const Form = ({
  handleformsubmit,
  planStatus,
  isLoading,
  planData,
  onSubmit,
  reCaptchaRef,
  percentage,
  invitationCode,
  finalSubmit,
  getProdId,
  getAddonProdId,
  isLearner
}) => {
  return (
    <>

      {!isLoading && invitationCode !== null &&
        <div className="register">
          <h1 className="register">
            Provide Following Information To Join
          </h1>
        </div>
      }

      {!isLoading && invitationCode === null && (
        <>
          <div className="em-row g-c-component">
            <NormalButton
              onClick={() => (window.location = getlinkedinLink(getProdId(), getAddonProdId()))}
              className="em-btn em-flex swg-icon"
              style={{ gap: 8 }}
            >
              <img src={require('../../../images/icons8-linkedin-logo.svg')} style={{ width: 15 }} />
              Sign Up With Linkedin
            </NormalButton>

            <NormalButton
              onClick={() => (window.location = getGoogleLink(getProdId(), getAddonProdId()))}
              className="em-btn em-flex swg-icon"
              style={{ gap: 8 }}
            >
              <img src={require('../../../images/icons8-google.svg')} style={{ width: 15 }} />
              Sign Up With google
            </NormalButton>
          </div>
          <h1 className="or-label">
            <span></span>OR<span></span>
          </h1>
        </>
      )}
      <form onSubmit={handleformsubmit} id="signUpForm">
        <div className="em-row">
          <div>
            <input
              type="text"
              className="form-control input"
              id="f-firstName"
              placeholder="Your Name*"
              name="firstName"
              maxlength="40"
              required
            />
          </div>
          <div>
            <input
              type="hidden"
              className="form-control input"
              id="f-lastName"
              placeholder="Last Name"
              name="lastName"
            />
          </div>
          {invitationCode === null && (
            <>
              <div>
                <input
                  type="email"
                  className="form-control input"
                  id="f-email"
                  name="email"
                  maxlength="60"
                  placeholder="Your Email Address*"
                  required
                />
                <div id="sgp-email-validation-msg" className="email-validation-msg">
                  <span className="text-danger"></span>
                </div>
              </div>
              <div>
                <input
                  type={isLearner === true ? 'hidden' : 'text'}
                  className="form-control input"
                  id="f-orgName"
                  placeholder="Your Organization Name*"
                  name="orgName"
                  maxlength="40"
                  required
                />
              </div>
              <div>
                <Select
                  id="f-country"
                  type="text"
                  placeholder="Your Country*"
                  name="countryCode"
                  menuPlacement="top"
                  options={countries}
                  defaultValue={[]}
                />
                <div id="sgp-country-validation-msg" className="email-validation-msg">
                  <span className="text-danger"></span>
                </div>
              </div>
            </>
          )}
        </div>
        <input type="hidden" name="group" className="form-control" id="f-group" />
        <input type="hidden" name="invitationCode" className="form-control" id="invitationCode" />
        <input type="hidden" name="productId" className="form-control" id="productId" />
        <input type="hidden" name="may_buy_addon_prodid" className="form-control" id="may_buy_addon_prodid" />
        <Recaptcha
          sitekey={`6LegAk0hAAAAABEv67lvT0FQTPkm7gBP93UVNE4P`}
          callback={onSubmit}
          className="g-recaptcha"
          size="invisible"
          onResolved={() => onSubmit('RECAPTCHA')}
          ref={reCaptchaRef}
        />
        <button type="submit" className={`em-btn submit-button`} onclick>
          {invitationCode === null ? 'Sign Up' : 'Join'}
        </button>
        <div id="alreadyLogin">
          <span>
            {' '}
            Already have an account?{' '}
            <a href={window.REACT_APP_SELF_URL} target="blank">
              Login here!
            </a>
          </span>
        </div>
      </form>
      <div className="text-centre" style={{ paddingTop: '20px' }}>
        {isLoading && (
          <>
            <div className="file-progress-sec">
              <ProgressBar
                variant="success"
                now={percentage}
                label={`${percentage}%`}
                style={{ width: '100%', margin: '0', borderRadius: '15px' }}
              />
              <p style={{ marginTop: 10, textAlign: 'center' }}>Please wait we are setting up your account</p>
            </div>
          </>
        )}
      </div>
    </>
  )
}
