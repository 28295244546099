import React from 'react'
import PopUP from '../../api/components-examples/PopUP'
import sessionExpire from '../../../src/images/sessionExpire.png'

const SessionIdleConfirmation = () => {

    const sessionReload = () => {
        window.location.reload()
    }

    return (
        <PopUP width="600px"
            title={''}
            bottomButton={false}
            bottomButtonCancel={false}
            loadingCancel={true}
            showScroll={false}
        >
            <div className="em-row">
                <div className="em-row em-tc em-tbm15">
                    <img src={sessionExpire} style={{ height: '100px' }}></img>
                </div>
                <div className="em-row em-tc em-tbm15 em-header">Your session has expired.</div>
                <div className="em-row em-tbm15" style={{ fontSize: '14px' }}>
                    <div
                        onClick={() => sessionReload()}
                        className="em-btn"
                        style={{ float: 'none', margin: '0 auto', width: 'fit-content' }}
                    >
                        Refresh
                    </div>
                </div>
            </div>
        </PopUP>
    )
}
export default SessionIdleConfirmation